<template>
  <div id="notfound">
    <div class="notfound">
      <p>
        <i
          style="text-align:center;display: inline-block;width:100%;"
          class="fa fa-exclamation-circle fa-4x"
          aria-hidden="true"
        ></i>
      </p>
      <div class="notfound-404">
        <h1 style="padding: 20px 0; font-size: 50px;">404</h1>
        <h2>Sorry. The page you're navigating to, is not available</h2>
      </div>
      <!-- <a href="#">Homepage</a> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.notfound {
  margin: auto;
  width: 70%;
  border: 3px solid red;
  padding: 30px;
  margin-top: 50px;
  .notfound-404 {
    text-align: center;
  }
}
</style>
